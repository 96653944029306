<template>
  <section ref="element" class="pg-quote">
    <h2 class="eyebrow font-xl-bold-vl font-md-bold-vs">
      {{ data.eyebrow }}
    </h2>
    <blockquote class="quotewrapper">
      <div class="inner">
        <QuoteMarkStart class="quotemark -start" />
        <p ref="quoteElement" class="quote font-display-3xl-zuehlke">
          {{ data.pgQuoteRefQuote.quoteText }}
        </p>
        <QuoteMarkEnd class="quotemark -end" />
      </div>
      <footer class="person">
        <p
          ref="authorNameElement"
          class="name font-lg-medium-vl font-md-medium-vs"
        >
          {{ data.pgQuoteRefQuote.quotePersonName }}
        </p>
        <p
          ref="authorRoleElement"
          class="description font-lg-bold-vl font-md-bold-vs"
        >
          {{ data.pgQuoteRefQuote.quotePersonDesc }}
        </p>
      </footer>
      <CrossHatchingGraphics class="-minimal-vs" />
    </blockquote>
    <SvgBlurryBlob ref="blurryBlobElement" class="blurryblob" />
  </section>
</template>

<script setup lang="ts">
import gsap from 'gsap';
import { SplitText } from 'gsap/SplitText';
import type { FragmentParagraphPgQuote } from '~/drupal/types';
import { useIntersectionState } from '~/utils/useIntersectionState';
import CrossHatchingGraphics from '~/components/paragraphs/components/CrossHatchingGraphics.vue';
import SvgBlurryBlob from '~/components/paragraphs/svgs/SvgBlurryBlob.vue';
import QuoteMarkStart from '~/components/paragraphs/svgs/QuoteMarkStart.vue';
import QuoteMarkEnd from '~/components/paragraphs/svgs/QuoteMarkEnd.vue';
import { parseCubicBezier } from '~/utils/parseCubicBezier';

defineProps<{
  data: FragmentParagraphPgQuote;
}>();

const quoteElement = ref<null | HTMLElement>(null);
const authorNameElement = ref<HTMLElement | null>(null);
const authorRoleElement = ref<HTMLElement | null>(null);

const { play, isAnimationFinished } = fadeInAnimation();

const element = ref<null | HTMLElement>(null);
useIntersectionState(element, {
  onFirstEnter: play,
  onLeave() {
    isAnimationFinished.value = false;
  },
});

function fadeInAnimation() {
  const ease = parseCubicBezier('cubic-bezier(0.75,0,.25,1)');
  const isAnimationFinished = ref<boolean>(false);
  const preferredMotion = usePreferredReducedMotion();

  const tl = gsap.timeline({
    paused: true,
    onComplete: function () {
      isAnimationFinished.value = true;
    },
  });

  onMounted(function () {
    if (preferredMotion.value === 'reduce') {
      return;
    }

    const split = new SplitText(quoteElement.value, {
      type: 'lines,chars',
      linesClass: 'line',
      charsClass: 'char',
    });

    tl.fromTo(
      split.chars.slice(split.chars.length - 20, split.chars.length - 1),
      { opacity: 0 },
      { opacity: 1, stagger: 0.055 },
    )
      // .fromTo(split.lines, { y: 100 }, { y: 0, stagger: 0.1 })
      .fromTo(
        split.lines[0],
        {
          x: 0,
        },
        { x: Math.min(150, window.innerWidth * 0.1), duration: 1, ease },
      )

      // .fromTo(
      //   split.lines[split.lines.length - 1],
      //   { 'clip-path': 'inset(0% 100% 0% 0%)' },
      //   { 'clip-path': 'inset(0% 0% 0% 0%)', duration: 2 },
      //   '<',
      // )
      .fromTo(
        ['.pg-quote .quotemark.-start', '.pg-quote .quotemark.-end'],
        { rotate: -45, scale: 0.2 },
        { rotate: 0, scale: 1, stagger: 0.5, duration: 1 },
        '<',
      )
      .fromTo(
        ['.pg-quote .quotemark.-start', '.pg-quote .quotemark.-end'],
        { opacity: 0 },
        { opacity: 1, stagger: 0.5, duration: 1 },
        '<25%',
      )
      .fromTo(
        '.pg-quote .blurryblob',
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 1.22,
          ease,
        },
      )
      .fromTo(
        [authorNameElement.value, authorRoleElement.value],
        { y: 30 },
        { y: 0, stagger: 0.1, duration: 2, ease },
        '<',
      )
      .fromTo(
        [authorNameElement.value, authorRoleElement.value],
        { opacity: 0 },
        { stagger: 0.1, opacity: 1, delay: 1 },
        '<',
      );
  });

  return {
    play,
    isAnimationFinished,
  };

  function play() {
    tl.play(0);
  }
}
</script>

<style scoped lang="scss">
.pg-quote {
  padding: var(--base-component-padding);
  position: relative;

  @media (--vs) {
    overflow-x: clip;
  }

  > .eyebrow {
    @media (--vs) {
      padding-bottom: 4.25rem;
    }

    @media (--vl) {
      padding-bottom: 8rem;
    }
  }

  > .quotewrapper {
    margin: 0;
    display: grid;
    position: relative;
    width: 100%;
    gap: 4rem;

    > .inner {
      position: relative;
      width: 100%;
      max-width: 90%;

      > .quotemark {
        color: var(--color-primary-purple-500, #965b9a);
        font-style: normal;
        font-weight: 500;
        line-height: 110%;
        position: absolute;

        @media (--vs) {
          width: 2.5rem;
        }

        @media (--vl) {
          width: 4.5rem;
        }

        &.-start {
          transform-origin: bottom center;

          @media (--vs) {
            inset: -3rem auto auto 0;
          }

          @media (--vl) {
            inset: -5rem auto auto 0;
          }
        }

        &.-end {
          transform-origin: top center;

          @media (--vs) {
            inset: auto -1rem -3rem auto;
          }

          @media (--vl) {
            inset: auto 22rem -8rem auto;
          }
        }
      }
    }

    > .person {
      justify-self: end;

      @media (--vs) {
        padding-right: 4.63rem;
        max-width: 12rem;
      }

      @media (--vl) {
        padding-right: 4.31rem;
        max-width: 20rem;
      }
    }
  }

  > .blurryblob {
    height: 90%;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    aspect-ratio: 16 / 9;
  }
}
</style>
