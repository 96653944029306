<template>
  <section ref="element" class="pg-cta-default">
    <p ref="eyebrowElement" class="eyebrow font-xl-bold-vl font-md-bold-vs">
      {{ data.eyebrow }}
    </p>
    <h3 v-if="data.title" ref="titleElement" class="title font-3xl-bold">
      {{ data.title }}
    </h3>
    <LinkHandler
      v-if="data.link?.url"
      :path="data.link.url"
      class="button-primary -animated -dark"
      :class="{ '-animation-start': !isAnimationFinished }"
      ><span class="label">{{ data.link.title }}</span>
    </LinkHandler>
    <CrossHatchingGraphics />
    <img
      ref="blurryBlobElement"
      src="/blurryblob.svg"
      class="blurryblob"
      alt=""
      role="presentation"
    />
  </section>
</template>

<script setup lang="ts">
import { SplitText } from 'gsap/SplitText';
import gsap from 'gsap';
import type { FragmentParagraphPgCtaDefault } from '~/drupal/types';
import { useIntersectionState } from '~/utils/useIntersectionState';
import LinkHandler from '~/components/links/LinkHandler.vue';
import CrossHatchingGraphics from '~/components/paragraphs/components/CrossHatchingGraphics.vue';
import { parseCubicBezier } from '~/utils/parseCubicBezier';

defineProps<{
  data: FragmentParagraphPgCtaDefault;
}>();

const eyebrowElement = ref<null | HTMLElement>(null);
const titleElement = ref<null | HTMLElement>(null);
const blurryBlobElement = ref<null | SVGElement>(null);

const { play, isAnimationFinished } = fadeInAnimation();

const element = ref<null | HTMLElement>(null);
useIntersectionState(
  element,
  {
    onFirstEnter: play,
  },
  {
    threshold: 0.15,
  },
);

function fadeInAnimation() {
  const isAnimationFinished = ref<boolean>(false);
  const preferredMotion = usePreferredReducedMotion();
  const ease = parseCubicBezier('cubic-bezier(0.75,0,.25,1)');

  const tl = gsap.timeline({
    paused: true,
    onComplete: function () {
      isAnimationFinished.value = true;
    },
  });

  onMounted(function () {
    if (preferredMotion.value === 'reduce') {
      isAnimationFinished.value = true;

      return;
    }

    const split = new SplitText(titleElement.value, {
      type: 'lines',
      linesClass: 'line',
    });

    tl.fromTo(eyebrowElement.value, { y: 100 }, { y: 0, duration: 0.75, ease })
      .fromTo(
        split.lines,
        { y: 100 },
        { y: 0, stagger: 0.1, duration: 0.75, ease },
        '<',
      )
      .fromTo(
        '.pg-cta-default .blurryblob',
        {
          scale: 0,
        },
        {
          scale: 1,
          duration: 1.99,
          ease,
        },
        '<',
      );
  });

  return {
    play,
    isAnimationFinished,
  };

  function play() {
    tl.play(0);
  }
}
</script>

<style scoped lang="scss">
.pg-cta-default {
  padding: var(--base-component-padding);
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;

  @media (--vs) {
    overflow-x: clip;
  }

  > .eyebrow {
    @media (--vs) {
      padding-bottom: 1.5rem;
    }

    @media (--vl) {
      padding-bottom: 1rem;
    }
  }

  > .title {
    text-align: center;

    @media (--vs) {
      padding-bottom: 2.5rem;
    }

    @media (--vl) {
      padding-bottom: 5.5rem;
    }
  }

  > .blurryblob {
    position: absolute;
    inset: 0;
    width: 100%;
    height: 100%;
    max-width: 100vw;
    pointer-events: none;
    z-index: -2;
    user-select: none;
    scale: 1.25;
  }
}
</style>
